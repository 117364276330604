export const topics = {
  machineLearning: {
    displayName: "🤖 Machine learning",
    children: [
      { name: "active learning" },
      { name: "unsupervised learning" },
      { name: "deep learning" },
      { name: "neural  nets" },
      { name: "ethics", value: "AI ethics" },
      { name: "computer vision" },
      { name: "NLP" },
      { name: "gradient descent" },
      { name: "artificial general intelligence" },
      { name: "backpropagation", value: "ML backpropagation" },
      { name: "boosting", value: "ML boosting" },
      { name: "hardware acceleration", value: "ML hardware acceleration" },
      { name: "CNN" },
      { name: "transfer learning" },
      // { name: "recommender systems" },
      // {name: ' text generation ?' },
      { name: " GAN  " },
    ],
  },
  computerScience: {
    displayName: "💻 Computer science",
    children: [
      { name: "indexing" },
      { name: "information retrieval" },
      { name: "compilers" },
      { name: "data structures" },
      { name: "data algorithms" },
      { name: "data processing" },
      { name: "distributed computing" },
      { name: "parallel computing" },
      { name: "quantum computing" },
      { name: "operating systems", value: "computer operating systems" },
      { name: "programming language design" },
      { name: "computer networks" },
      { name: "cyber security" },
      { name: "virtualization" },
      { name: "cryptography" },
    ],
  },
  software: {
    displayName: "👩‍💻 Software",
    children: [
      { name: "agile development" },
      { name: "user interface design" },
      { name: "cloud storage" },
      { name: "startups" },
      { name: "databases" },
      { name: "open source" },
      { name: "debugging" },
      { name: "metrics", value: "software metrics" },
      { name: "JavaScript" },
      { name: "Android" },
      { name: "testing", value: "software testing" },
      { name: "web applications" },
      // { name: "brain computer interface" },
      { name: "VR software" },
      { name: "OAuth" },
      { name: "git", value: "software git" },
    ],
  },
  biology: {
    displayName: "🧬 Biology",
    children: [
      { name: "COVID-19" },
      { name: "protein folding" },
      { name: "CRISPIR" },
      { name: "origin of life" },
      { name: "single-molecule manipulation" },
      { name: "morphogenesis and development" },
      { name: "bacterial conjugation" },
      { name: "DNA sequencing" },
      { name: "RNA editing" },
      { name: "mutational processes" },
      { name: "neuronal dynamics" },
      { name: "cancer cell research" },
      { name: "dynamic speciation" },
      // { name: "reverse aging" },
      { name: "directed evolution" },
      { name: "computational biology" },
    ],
  },
  astrology: {
    displayName: "💫 Astrology",
    children: [
      { name: "galactic formation" },
      { name: "early universe" },
      { name: "extraterrestrial life" },
      { name: "Fermi paradox" },
      { name: "Drake equation" },
      { name: "dark matter" },
      { name: "primordial black holes" },
      { name: "supermassive black holes" },
      { name: "supernovae" },
      { name: "telescope design" },
      { name: "brown dwarfs" },
      { name: "star formation" },
      { name: "prostellar systems" },
      { name: "gravitational radiation" },
      { name: "extragalactic distance scale" },
    ],
  },
  physics: {
    displayName: "⚛️ Physics",
    children: [
      { name: "quantum theory" },
      { name: "accelerator", value: "accelerator physics" },
      { name: "atmospheric", value: "atmospheric physics" },
      { name: "atomic", value: "atomic physics" },
      { name: "biological", value: "biological physics" },
      { name: "chemical", value: "chemical physics" },
      { name: "computational", value: "computational physics" },
      { name: "fluid dynamics" },
      { name: "superconductors" },
      { name: "geophysics" },
      { name: "medical physics" },
      { name: "optics" },
      { name: "plasma", value: "plasma physics" },
      { name: "nuclear fusion" },
      { name: "gravitational wave astronomy" },
    ],
  },
  math: {
    displayName: "➕ Math",
    children: [
      { name: "Moduli space" },
      { name: "combinatorics" },
      { name: "graph theory" },
      { name: "symplectic geometry" },
      { name: "complex geometry" },
      { name: "Riemannian geometry" },
      { name: "topology" },
      { name: "PDE" },
      { name: "Banach space" },
      { name: "Hilbert space" },
      { name: "discrete math" },
      { name: "Bayesian inference" },
      { name: "decision theory" },
      { name: "Gaussian" },
      { name: "spatial statistics" },
    ],
  },
  economics: {
    displayName: "💸 Economics",
    children: [
      { name: "automated trading" },
      { name: "decision theory" },
      { name: "cryptocurrency" },
      { name: "econometrics" },
      { name: "general equilibrium", value: "general equilibrium economics" },
      { name: "capital allocation" },
      { name: "market design" },
      { name: "social choice" },
      { name: "Monte Carlo", value: "Monte Carlo finance" },
      { name: "investment strategies", value: "investment strategies finance" },
      { name: "risk measurement", value: "risk measurement finance" },
      { name: "derivatives", value: "derivatives finance" },
      { name: "econophysics" },
      { name: "market microstructure" },
      { name: "auction design" },
      // { name: "Agent-based modeling" },
    ],
  },
  fun: {
    displayName: "🎉 Fun",
    children: [
      { name: "movie plots" },
      { name: "football (or any sport)", value: "football" },
      { name: "hot hand basketball" },
      { name: "rap lyrics" },
      { name: "music generation" },
      { name: "music cognition" },
      { name: "Twitter elections" },
      { name: "fake news" },
      { name: "fashion" },
      { name: "video games" },
      { name: "Reddit" },
      { name: "coffee" },
      // { name: "chimpanzees" },
      { name: "future cities" },
      // { name: "ranking countries, future cities" },
      { name: "Darknet" },
      { name: "baby names", value: "given baby names" },
    ],
  },
};
