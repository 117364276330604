import React from "react";
import styled from "styled-components";
import pdfjsLib from "pdfjs-dist/webpack";
import moment from "moment";
import { motion, AnimatePresence } from "framer-motion";
import IconClose from "./Icons/IconClose.js";
import IconPlus from "./Icons/IconPlus.js";
import IconMinus from "./Icons/IconMinus.js";
import ArrowIcon from "./Icons/ArrowIcon.js";
import Viewer from "./Viewer";
import EmptyState from "./EmptyState";
import ReactTooltip from "react-tooltip";
import MathJax from "react-mathjax-preview";

const zoomLevels = [0.5, 0.7, 0.9, 1.0, 1.1, 1.25, 1.5, 2];

const Right = (props) => {
  const [doc, setDoc] = React.useState({});
  const [PDF, setPDF] = React.useState("");
  const [paperMode, setPaperMode] = React.useState(false);
  const [scaleIndex, setScaleIndex] = React.useState(3);
  const [wheelScale, setWheelScale] = React.useState(1);
  // const [loading, setLoading] = React.useState(false);

  const paper = props.focusedPaper;
  const isMobile = props.isMobile;

  // focused paper changes
  React.useEffect(() => {
    setPaperMode(false);
    if (props.focusedPaper) {
      getPDF(props.focusedPaper.link);
    }
  }, [props.focusedPaper]);

  const handlePaperMode = () => {
    if (!paperMode) {
      getPDF(paper.link);
      setScaleIndex(3);
    }
    setPaperMode(!paperMode);
  };

  // keypress
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [paperMode, props.focusedPaper, scaleIndex]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && props.focusedPaper) {
      if (!paperMode) {
        getPDF(paper.link);
        setScaleIndex(3);
      }
      setPaperMode(!paperMode);
    }
    // keyboard zooming
    else if (
      e.keyCode === 187 &&
      paperMode &&
      scaleIndex < zoomLevels.length - 1
    ) {
      setScaleIndex(scaleIndex + 1);
    } else if (e.keyCode === 189 && paperMode && scaleIndex > 0) {
      setScaleIndex(scaleIndex - 1);
    }
  };

  const getPDF = (links) => {
    let pdf;
    links.forEach((link) => {
      if (link.$.title === "pdf") {
        pdf = link.$.href + ".pdf";
        pdf = pdf.replace("http", "https");
        setPDF(pdf);
        renderPDF(pdf);
      }
    });
  };

  const renderPDF = (url) => {
    let loadingTask = pdfjsLib.getDocument(url);
    // console.log("rendering...");
    loadingTask.promise.then(
      (doc) => {
        // console.log(`Document ${url} loaded ${doc.numPages} page(s)`);
        setDoc(doc);
      },
      (reason) => {
        // console.error(`Error during ${url} loading: ${reason}`);
      }
    );
  };

  // trackpad zoom; doesnt really work rn
  const handleWheel = (e) => {
    // e.preventDefault();
    if (e.ctrlKey) {
      const newScale = wheelScale * 1 + -e.deltaY * 0.01;
      const bounded = Math.max(0.5, Math.min(newScale, 2)).toFixed(2);

      setWheelScale(bounded);
    }
  };

  return (
    <Wrapper>
      {paper && !isMobile && (
        <SuperTrapper
          initial={!isMobile ? { opacity: 0 } : {}}
          animate={!isMobile ? { opacity: 1, transition: { delay: 0.5 } } : {}}
          exit={!isMobile ? { opacity: 0 } : {}}
        >
          {/* something for mobile */}
          <CollapseWrapper>
            <CloseButton
              style={{
                boxShadow: "none",
                background: "#f2f2f2",
                position: "static",
              }}
              onClick={() => props.handleFocus(null)}
            >
              <IconClose />
            </CloseButton>
          </CollapseWrapper>
          <PaperButtonWrapper
            variants={buttonVariants}
            initial={false}
            animate={paperMode ? "open" : "closed"}
            custom={isMobile}
          >
            <AnimatePresence>
              {!paperMode && (
                <PaperButton
                  onClick={handlePaperMode}
                  initial={!isMobile ? { opacity: 0 } : {}}
                  animate={!isMobile ? { opacity: 1 } : {}}
                  exit={!isMobile ? { opacity: 0 } : {}}
                  transition={{ duration: 0.2 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FakePaper>
                        {/* <WireText style={{ width: "50%" }} />
                        <WireText />
                        <WireText />
                        <WireText /> */}
                        <FakeTitle>{paper.title}</FakeTitle>

                        <FakeTitle style={{ opacity: 0.5 }}>
                          {moment.utc(paper?.published[0]).format("YYYY")}
                        </FakeTitle>
                      </FakePaper>
                      View paper
                      <ArrowWrapper>
                        <ArrowIcon />
                      </ArrowWrapper>
                    </div>
                    {!isMobile && <ShortcutKey>Enter</ShortcutKey>}
                  </div>
                </PaperButton>
              )}
            </AnimatePresence>
          </PaperButtonWrapper>
        </SuperTrapper>
      )}
      <AnimatePresence>
        {!paper && props.searchValue.length === 0 && !isMobile && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <EmptyState handleSearch={props.handleSearch} />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!paperMode && paper && (
          <Preview
            initial={!isMobile ? { opacity: 0 } : {}}
            animate={
              !isMobile ? { opacity: 1, transition: { delay: 0.25 } } : {}
            }
            exit={!isMobile ? { opacity: 0 } : {}}
          >
            <Title>{paper.title}</Title>
            <DatesWrapper>
              <Date>
                Published {moment.utc(paper?.published[0]).format("MMM YYYY")}
              </Date>
              {paper.id && (
                <>
                  <Dot />
                  <Link href={paper.id[0]} target="_blank">
                    View on arXiv
                  </Link>
                </>
              )}
            </DatesWrapper>
            {isMobile && !paperMode && paper && (
              <MobileButtonWrapper>
                <MobileButton
                  onClick={() => props.handleFocus(null)}
                  style={{ color: "#757575" }}
                >
                  {/* <ArrowWrapper> */}
                  <div
                    style={{
                      width: 16,
                      height: 16,
                      flexShrink: 0,
                      marginRight: 8,
                      opacity: 0.5,
                    }}
                  >
                    <ArrowIcon />
                  </div>
                  {/* </ArrowWrapper> */}
                  Back
                </MobileButton>
                <MobileButton
                  onClick={handlePaperMode}
                  style={{ marginLeft: 8, marginRight: 8 }}
                >
                  View paper
                </MobileButton>
                {PDF && (
                  <MobileButton>
                    <a href={PDF} target="_blank">
                      Open paper
                    </a>
                  </MobileButton>
                )}
              </MobileButtonWrapper>
            )}
            <InfoWrapper>
              <div style={{ flexGrow: 1 }}>
                <Label>Abstract</Label>
                <Summary>
                  <MathJax math={paper.summary} msDelayDisplay={0} />
                </Summary>
              </div>

              <AuthorsWrapper>
                <Label>Authors</Label>
                {paper.author &&
                  paper.author.map((authors, i) => (
                    <Author key={i}>{authors.name}</Author>
                  ))}
              </AuthorsWrapper>
            </InfoWrapper>
          </Preview>
        )}
      </AnimatePresence>

      {paperMode && (
        <>
          <Viewer
            document={doc}
            scale={zoomLevels[scaleIndex]}
            isMobile={isMobile}
            handlePaperMode={handlePaperMode}
            // scale={wheelScale}
            // handleLoading={handleLoading}
          />
          {!isMobile && (
            <ZoomWrapper>
              <ZoomButton
                data-for="zoomOut"
                data-tip="Minus"
                disabled={scaleIndex === 0}
                onClick={() => setScaleIndex(scaleIndex - 1)}
              >
                <IconMinus />
              </ZoomButton>
              <DisplayScale>
                {(zoomLevels[scaleIndex] * 100).toFixed(0)}%
              </DisplayScale>
              <ZoomButton
                data-for="zoomIn"
                data-tip="Plus"
                disabled={scaleIndex === zoomLevels.length - 1}
                onClick={() => setScaleIndex(scaleIndex + 1)}
              >
                <IconPlus />
              </ZoomButton>
            </ZoomWrapper>
          )}
          {!isMobile && (
            <CloseButton
              data-for="close"
              data-tip="Enter"
              onClick={handlePaperMode}
            >
              <IconClose />
            </CloseButton>
          )}

          {!isMobile && (
            <>
              <ReactTooltip
                id="close"
                effect="solid"
                place="left"
                delayShow={300}
              >
                Close
                <ShortcutKey dark={true}>Enter</ShortcutKey>
              </ReactTooltip>
              <ReactTooltip
                id="zoomOut"
                effect="solid"
                place="top"
                delayShow={300}
              >
                Click to zoom out, or hit
                <ShortcutKey dark={true} style={{ marginLeft: 8 }}>
                  -
                </ShortcutKey>
              </ReactTooltip>
              <ReactTooltip
                id="zoomIn"
                effect="solid"
                place="top"
                delayShow={300}
              >
                Click to zoom in, or hit
                <ShortcutKey dark={true} style={{ marginLeft: 8 }}>
                  +
                </ShortcutKey>
              </ReactTooltip>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  flex-grow: 1;
  position: relative;
  overflow: scroll;
  @media (max-width: 1080px) {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 100vw;
    top: 0;
  }
`;

const buttonVariants = {
  open: () => ({
    height: "100vh",
    width: "100%",
    y: 0,
    borderRadius: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 40,
      delay: 0.2,
    },
  }),
  closed: (isMobile) => ({
    height: "auto",
    width: !isMobile ? "min(100% - 80px, 900px)" : "min(100% - 48px, 900px)",
    x: "-50%",
    y: !isMobile ? 62 : 80,
    borderRadius: 4,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 33,
    },
  }),
};

const SuperTrapper = styled(motion.div)`
  // background: #ededed;
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 100%;
`;

const CollapseWrapper = styled.div`
  display: none;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    justify-content: flex-end;
    position: sticky;
  }
`;

const PaperButtonWrapper = styled(motion.div)`
  background: #f2f2f2;
  position: absolute;
  left: 50%;
  max-width: 100%;
`;

const PaperButton = styled(motion.button)`
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  background: transparent;
  color: #0eb72a;
  transition: color 200ms ease-out;
  :hover {
    color: #12cf31;
  }
  :hover svg {
    opacity: 1;
    transform: translateX(-4px);
  }
  :hover div {
    opacity: 1;
  }
  :hover svg path {
    fill: #12cf31;
  }
`;

const FakePaper = styled.div`
  height: 42px;
  width: 35px;
  color: black;
  font-weight: 400;
  background: white;
  border-radius: 3px;
  margin-right: 16px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  padding: 6px;
  transition: all 150ms ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // testing
`;

const FakeTitle = styled.p`
  font-size: 5px;
  line-height: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
`;

const WireText = styled.div`
  height: 4px;
  background: #e6e6e6;
  margin-bottom: 4px;
`;

const ArrowWrapper = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 8px;
  transform: rotate(180deg);
  opacity: 0.6;
  transition: all 150ms ease-out;
  & svg {
    transition: all 150ms ease-out;
  }
  & svg path {
    transition: all 150ms ease-out;
  }
`;

const ShortcutKey = styled.div`
  font-size: 12px;
  padding: 4px;
  border: solid 1px grey;
  border-radius: 4px;

  opacity: 1;
  border-color: ${(props) => (props.dark ? "#808080" : "#ccc")};
  color: ${(props) => (props.dark ? "#ededed" : "#757575")};
  margin-right: ${(props) => (props.dark ? "0" : "8px")};
  margin-left: ${(props) => (props.dark ? "16px" : "0")};
`;

const Preview = styled(motion.div)`
  max-width: 980px;
  margin: 0 auto;
  margin-top: 124px;
  padding: 40px;
  // margin: 40px auto;
  @media (max-width: 1080px) {
    margin-top: 24px;
    padding: 24px;
    padding-bottom: 80px;
  }
`;

const Title = styled.h1`
  // font-size: 24px;
  font-size: 26px;
  // font-weight: 500;
  font-weight: 400;
  margin-bottom: 8px;
  line-height: 32px;
  font-family: "Nantes", "Inter", sans-serif;
`;

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Date = styled.p`
  font-size: 14px;
  color: #757575;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: black;
  margin: 0 8px;
  opacity: 0.2;
`;

const Link = styled.a`
  font-size: 14px;
  color: #757575;
  text-decoration: none;
  border-bottom: solid 1px lightgrey;
  transition: color 150ms ease-out;
  :hover {
    color: black;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const Summary = styled.div`
  font-size: 15px;
  line-height: 24px;
`;

const AuthorsWrapper = styled.div`
  width: 180px;
  flex-shrink: 0;
  margin-left: 72px;
  @media (max-width: 1080px) {
    margin-left: 0;
    width: 100%;
    margin-top: 24px;
  }
`;

const Author = styled.p`
  font-size: 15px;
  line-height: 24px;
`;

const ZoomWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: white;
  padding: 0px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
`;

const DisplayScale = styled.div`
  font-size: 12px;
  margin: 0 4px;
  width: 40px;
  flex-shrink: 0;
  text-align: center;
`;

const ZoomButton = styled.button`
  outline: none;
  height: 32px;
  width: 32px;
  padding: 8px;
  background: white;
  border: none;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 150ms ease-out;
  :hover {
    opacity: 0.8;
  }
  :disabled {
    opacity: 0.2;
  }
`;

const CloseButton = styled.button`
  outline: none;
  position: absolute;
  top: 16px;
  right: 24px;
  height: 32px;
  width: 32px;
  padding: 8px;
  background: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  & svg {
    opacity: 0.4;
    transition: opacity 150ms ease-out;
  }
  :hover {
    & svg {
      opacity: 0.8;
    }
  }
`;

const MobileButtonWrapper = styled.div`
  margin: 24px 0;
  width: calc(100vw - 48px);
  display: flex;
  justify-content: space-between;
`;

const MobileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31%;
  padding: 12px 8px;
  outline: none;
  border: none;
  background: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #12cf31;
  background: #f2f2f2;
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

export default Right;
