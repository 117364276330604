import React from "react";

const IconPlus = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 9h-6V3a1 1 0 10-2 0v6H3a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2z"
      fill="#000"
    />
  </svg>
);

export default IconPlus;
