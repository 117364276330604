import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Right from "./Right";
import RightTest from "./RightTest";
import Branding from "./Branding";
import { motion, AnimatePresence } from "framer-motion";

const App = () => {
  const [focusedPaper, setFocus] = useState(null);
  const [overrideSearch, setOverrideSearch] = useState("");
  const [searchValue, setValue] = useState("");
  // const [inputFocused, setInputFocused] = useState(false);

  const handleFocus = (paper) => {
    setFocus(paper);
  };

  const handleSearch = (newValue) => {
    setValue(newValue);
  };

  const inspoSearch = (newValue) => {
    setOverrideSearch(newValue);
    handleSearch(newValue);
    // setFocus(null);
  };

  // detect if mobile
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const isMobile = vw <= 1080 ? true : false;

  // render() {
  return (
    <Wrapper
      className="App"
      initial={!isMobile ? { opacity: 0 } : {}}
      animate={!isMobile ? { opacity: 1 } : {}}
    >
      {!isMobile ? (
        <>
          {/* <Branding /> */}
          <Sidebar
            handleFocus={handleFocus}
            handleSearch={handleSearch}
            searchValue={searchValue}
            isMobile={isMobile}
            overrideSearch={overrideSearch}
            // handleFocus={setInputFocused}
          />
          <Right
            focusedPaper={focusedPaper}
            handleFocus={handleFocus}
            searchValue={searchValue}
            handleSearch={inspoSearch}
            isMobile={isMobile}
            // inputFocused={inputFocused}
          />
        </>
      ) : (
        <MobileWrapper focusedPaper={focusedPaper ? true : false}>
          <Sidebar
            handleFocus={handleFocus}
            handleSearch={handleSearch}
            searchValue={searchValue}
            isMobile={isMobile}
          />
          <Right
            focusedPaper={focusedPaper}
            handleFocus={handleFocus}
            searchValue={searchValue}
            isMobile={isMobile}
          />
        </MobileWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  @media screen and (max-width: 1080px) {
    width: 100%;
    // max-width: 100%;
    overflow: hidden;
    display: block;
    position: static;
    height: 100vh;
  }
`;

const MobileWrapper = styled.div`
  position: relative;
  transform: ${(props) =>
    props.focusedPaper ? "translateX(-100%)" : "translateX(0%)"};

  transition: transform 200ms ease-out;
`;

export default App;
