import React, { Component, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";
import Spinner2 from "./Spinner2";
import IconClose from "./Icons/IconClose.js";
// pdf
import { PDFJS as PDFJSViewer } from "pdfjs-dist/web/pdf_viewer.js";
import "./Viewer.css";
import "pdfjs-dist/web/pdf_viewer.css";

class Viewer extends Component {
  constructor(props) {
    super(props);
    this.initEventBus();
    this.loadingRef = React.createRef();

    this.state = {
      doc: null,
      scale: undefined,
      loading: false,
    };
  }

  initEventBus() {
    let eventBus = new PDFJSViewer.EventBus();
    eventBus.on("pagesinit", (e) => {
      // console.log("init");
      // hide loader
      const loadingNode = this.loadingRef.current;
      loadingNode.style.opacity = 0;
      loadingNode.style.marginTop = 0;
    });
    this._eventBus = eventBus;
  }

  componentDidMount() {
    let viewerContainer = ReactDOM.findDOMNode(this);
    // console.log("loading");

    // show loader
    const loadingNode = this.loadingRef.current;
    loadingNode.style.opacity = 1;
    loadingNode.style.marginTop = "15vh";

    this._pdfViewer = new PDFJSViewer.PDFViewer({
      container: viewerContainer,
      eventBus: this._eventBus,
    });
  }

  componentDidUpdate() {
    if (!this.state.doc) {
      this.setState({ doc: this.props.document });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // this.setState({ doc: this.props.document });
    // console.log(this.props.document);

    if (this.state.doc !== nextState.doc) {
      this._pdfViewer.setDocument(nextState.doc);
    }
    if (this.state.scale !== nextState.scale) {
      this._pdfViewer.currentScale = nextState.scale;
    }
    if (this.props.scale !== nextProps.scale) {
      this._pdfViewer.currentScale = nextProps.scale;
    }

    const loadingNode = this.loadingRef.current;
    loadingNode.style.transition = "all 500ms ease-out";
    loadingNode.style.opacity = 0;
  }

  render() {
    const isMobile = this.props.isMobile;

    return (
      <motion.div
        className="Viewer"
        initial={!isMobile ? { opacity: 0 } : {}}
        animate={!isMobile ? { opacity: 1 } : {}}
        transition={!isMobile ? { delay: 0.5 } : {}}
      >
        <motion.div
          className="pdfViewer"
          initial={!isMobile ? { opacity: 0 } : {}}
          animate={!isMobile ? { opacity: 1 } : {}}
          transition={!isMobile ? { delay: 0.7 } : {}}
        />
        {isMobile && (
          <CloseButton onClick={this.props.handlePaperMode}>
            <ArrowWrapper>
              <IconClose />
            </ArrowWrapper>
            Close
          </CloseButton>
        )}
        <LoadingWrapper ref={this.loadingRef}>
          <Spinner2 large={true} style={{ marginBottom: 16 }} />
          <LoadingText>Loading paper</LoadingText>
        </LoadingWrapper>
      </motion.div>
    );
  }
}

Viewer.propTypes = {
  onInit: PropTypes.func,
  onScaleChanged: PropTypes.func,
  document: PropTypes.any,
};

const LoadingWrapper = styled.div`
  margin: 0 auto;
  margin-top: 15vh;
  text-align: center;
`;

const LoadingText = styled.div`
  font-size: 14px;
  opacity: 0.5;
  padding-top: 8px;
`;

const CloseButton = styled.button`
  position: sticky;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  outline: none;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  background: white;
  border: none;
  border-radius: 100px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;

const ArrowWrapper = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 8px;
  opacity: 0.7;
`;

export default Viewer;
