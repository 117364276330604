import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import _ from "lodash";
import { useDebouncedCallback } from "use-debounce";
import Spinner2 from "./Spinner2";
import Logo from "./Icons/Logo.png";
import SearchIcon from "./Icons/SearchIcon";
import SortingTabs from "./SortingTabs";
import EmptyState from "./EmptyState";

const axios = require("axios");
const parseString = require("xml2js").parseString;

const Sidebar = (props) => {
  const [results, setResults] = React.useState([]);
  const [focusedKey, setFocus] = React.useState(-1);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [sortBy, setSort] = React.useState("relevance");
  const [numToFetch, setNumber] = React.useState(25);
  const [localValue, setLocalValue] = React.useState("");
  const [aboutVisible, toggleAbout] = React.useState(false);

  // input
  const inputRef = React.useRef(null);

  // call search if 1) value changes or 2) sortBy changes
  React.useEffect(() => {
    if (props.searchValue.length === 0) {
      setResults([]);
    } else {
      getPapers(props.searchValue, sortBy, 0);
    }
  }, [sortBy, props.searchValue]);

  React.useEffect(() => {
    if (numToFetch === 0 || props.searchValue.length === 0) return;
    getPapers(props.searchValue, sortBy, numToFetch - 25);
  }, [numToFetch]);

  // override search value if from "need inspo"
  React.useEffect(() => {
    setLocalValue(props.overrideSearch);
  }, [props.overrideSearch]);

  const prepURL = (query, sortType, startIndex) => {
    if (sortType === "relevance") {
      return `https://export.arxiv.org/api/query?search_query=all:${query}&start=${startIndex}&max_results=25`;
    } else if (sortType === "recent") {
      return `https://export.arxiv.org/api/query?search_query=all:${query}&start=${startIndex}&max_results=25&sortBy=submittedDate&sortOrder=descending`;
    }
  };

  // keypress
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedKey, results]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (results && focusedKey === results.length - 1) return;
      e.preventDefault();
      setFocus(focusedKey + 1);
      props.handleFocus(results[focusedKey + 1]);
      handleAutoScroll();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    } else if (e.key === "ArrowUp") {
      if (focusedKey === 0) return;
      e.preventDefault();
      setFocus(focusedKey - 1);
      props.handleFocus(results[focusedKey - 1]);
      handleAutoScroll();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const handleAutoScroll = () => {
    const activePaper = document.getElementById("activePaper");
    const rect = activePaper.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    if (!isVisible) {
      // do the scrolling
      activePaper.scrollIntoView(false);
    }
  };

  const getPapers = (query, sortType, startIndex) => {
    const url = prepURL(query, sortType, startIndex);

    // set which location to render spinner
    if (startIndex === 0) {
      setLoading(true);
    } else {
      setFetching(true);
    }

    axios
      .get(url)
      .then(function (response) {
        // handle success
        parseString(response.data, function (err, result) {
          const entries = result.feed.entry;
          // get the actual papers
          if (startIndex === 0) {
            setResults(entries);
          } else {
            const newResults = [...results];
            newResults.push(...entries);
            // console.log(newResults);
            setResults(newResults);
          }
          // I also need to figure out how to fetch the next 25
        });
        setLoading(false);
        setFetching(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  // acatually 2 functions: one to set value, one to call
  const handleChange = (value) => {
    setLocalValue(value);
    debounced.callback(value);
  };

  const debounced = useDebouncedCallback((value) => {
    props.handleSearch(value);
  }, 200);

  const updateSortType = (newType) => {
    setSort(newType);
  };

  const setFocusedPaper = (paper, index) => {
    setFocus(index);
    props.handleFocus(results[index]);
  };

  return (
    <Wrapper>
      {/* <Header> */}
      {props.isMobile && (
        <MobileBanner>
          🖥 FYI this experience is much better on desktop
        </MobileBanner>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 24,
        }}
      >
        <Wordmark>
          <LogoWrapper>
            <StyledLogo src={Logo} />
          </LogoWrapper>
          <Mark>research-search</Mark>
        </Wordmark>
        <Link href="https://twitter.com/lawderpaul" target="_blank">
          by @lawderpaul
        </Link>
        {/* <AboutParent>
          <AboutLink onClick={() => toggleAbout(!aboutVisible)}>
            About
          </AboutLink>
          <AnimatePresence>{aboutVisible && <AboutInfo />}</AnimatePresence>
        </AboutParent> */}
      </div>

      <InputWrapper
      // animate={isOpen ? "active" : "inactive"}
      // variants={inputVariants}
      >
        <Input
          ref={inputRef}
          type="text"
          placeholder="Search papers..."
          value={localValue}
          onChange={(e) => handleChange(e.target.value)}
        />
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        {/* {loading && <Spinner />} */}
        {loading && (
          <SpinnerWrapper>
            <Spinner2 />
          </SpinnerWrapper>
        )}
      </InputWrapper>
      {props.isMobile && props.searchValue.length === 0 && <EmptyState />}
      <Results>
        {props.searchValue.length === 0 || !results ? (
          <p></p>
        ) : (
          <div>
            <SortingTabs sortBy={sortBy} handleSort={updateSortType} />

            {results.map((paper, index) => (
              // <Paper key={index} paper={paper} />
              <Paper
                key={paper.id[0]}
                active={focusedKey === index}
                onClick={() => setFocusedPaper(paper, index)}
                id={
                  focusedKey === index ? "activePaper" : `inactivePaper${index}`
                }
              >
                <Title>{paper.title}</Title>
                <Subtitle>
                  <span>{moment(paper.published[0]).utc().format("YYYY")}</span>
                  <Dot />
                  <Preview>{paper.summary}</Preview>
                </Subtitle>
              </Paper>
            ))}
            {numToFetch <= 225 &&
              results.length % 25 === 0 &&
              results.length > 0 && (
                <FetchWrapper>
                  <div style={{ width: 21, marginLeft: 24 }}>
                    {fetching && <Spinner2 />}
                  </div>

                  <FetchButton
                    disabled={fetching}
                    onClick={() => setNumber(numToFetch + 25)}
                  >
                    See more
                  </FetchButton>
                  <div style={{ width: 21, marginRight: 24 }} />
                </FetchWrapper>
              )}

            {numToFetch > 225 && !fetching && (
              <FetchWrapper>
                <FetchSpan>
                  Ok chill that's {results.length} papers. NERD!
                </FetchSpan>
              </FetchWrapper>
            )}
          </div>
        )}
      </Results>
    </Wrapper>
  );
};

const AboutInfo = () => {
  return (
    <AboutWrapper
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
    >
      <div>
        People publish a lot of fascinating research out to the world, yet the
        tools to consume this research are underwhelming. This is an interface
        optimized for searching research: please wander about topics and papers
        to your brain's content.
      </div>
    </AboutWrapper>
  );
};

const Wrapper = styled.div`
  // width: 400px;
  width: 420px;
  max-width: 29vw;
  flex-shrink: 0;
  // position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: scroll;
  border-right: solid 1px #ededed;
  @media screen and (max-width: 1080px) {
    // width: 100%;
    width: 100vw;
    max-width: none;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Wordmark = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 16px;
  // display: none;
`;

const Mark = styled.span`
  font-size: 18px;
  font-weight: 400;
  margin-left: 12px;
  font-family: "Nantes", "Inter", sans-serif;
`;

const LogoWrapper = styled.div`
  height: 32px;
  width: 32px;
  background: #00ff00;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

const StyledLogo = styled.img`
  height: 22px;
  width: 22px;
`;

const Link = styled.a`
  font-size: 12px;
  color: #757575;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  transition: all 150ms ease-out;
  cursor: pointer;
  margin-top: 2px;
  :hover {
    color: black;
    border-bottom: solid 1px lightgrey;
  }
`;

const AboutParent = styled.div`
  position: relative;
`;

const AboutLink = styled.div`
  background: transparent;
  cursor: pointer;
  font-size: 12px;

  padding: 4px 8px;
  border-radius: 50px;
  opacity: 1;
  color: #777;
  transition: background 150ms ease-out;
  user-select: none;
  :hover {
    background: #f2f2f2;
  }
`;

const AboutWrapper = styled(motion.div)`
  position: absolute;
  top: 40px;
  right: 0;
  width: calc(420px - 48px);
  max-width: calc(30vw - 48px);
  padding: 16px;
  background: white;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 20px;
  color: black;
`;

const InputWrapper = styled(motion.div)`
  // position: relative;
  position: sticky;
  top: 8px;
  padding: 0 24px;
  margin-bottom: 16px;
  z-index: 10;

  // top: 16px;
  // margin-top: 24px;
`;

const Input = styled.input`
  height: 44px;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  background: white;
  width: 100%;
  padding: 0 12px;
  padding-left: 44px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: box-shadow 150ms ease-out;
  :focus {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  }
  ::selection {
    background: rgba(20, 235, 56, 0.3);
  }
`;

const SearchIconWrapper = styled.div`
  width: 26px;
  height: 26px;
  position: absolute;
  top: 9px;
  left: 33px;
  padding: 4px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 36px;
`;

const Results = styled.div`
  // margin-top: 96px;
  padding: 0px;
  @media screen and (max-width: 1080px) {
    padding-bottom: 80px;
  }
`;

const Paper = styled.div`
  padding: 12px 24px;
  background: ${(props) => (props.active ? "#EAFAEE" : "transparent")};
  // border-radius: 8px;
  cursor: pointer;
  border-right: solid 3px;
  border-color: ${(props) => (props.active ? "#14EB38" : "transparent")};
  :hover {
    background: ${(props) => (props.active ? "#EAFAEE" : "#f2f2f2")};
  }
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
`;

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Dot = styled.div`
  margin: 0 8px;
  height: 6px;
  width: 6px;
  background: lightgrey;
  border-radius: 50%;
  flex-shrink: 0;
`;

const Preview = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FetchWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FetchButton = styled.button`
  outline: none;
  border: none;
  padding: 4px 12px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.08);
  opacity: 0.7;
  transition: opacity 150ms ease-out;
  :hover {
    opacity: 1;
  }
`;

const FetchSpan = styled.span`
  font-size: 12px;
  color: grey;
  margin: 0 auto;
`;

const MobileBanner = styled.div`
  background: #f2f2f2;
  background: #14eb38;
  background: #12cf31;
  color: white;
  padding: 8px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
`;

export default Sidebar;
