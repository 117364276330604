import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

const RightTest = (props) => {
  // const paper = props.focusedPaper;
  // console.log("paper: " + paper);
  console.log(props);
  // // detect if mobile
  // const vw = Math.max(
  //   document.documentElement.clientWidth || 0,
  //   window.innerWidth || 0
  // );
  // console.log(vw);
  // const isMobile = vw <= 1080 ? true : false;
  // console.log("mobile: " + isMobile);

  // paper ? console.log("there is a paper") : console.log("no paper");

  // const isVisible = !isMobile || paper;
  // console.log(isVisible);
  // console.log("isVisible: " + isVisible);

  return (
    <AnimatePresence>
      {props.isFoo && (
        <Wrapper
          // initial={{ y: 0 }}
          // animate={{ y: 50 }}
          // exit={{ y: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <p>Hello</p>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

const Wrapper = styled(motion.div)`
  background: red;
  width: 100%;
  height: 95vh;
  // top: 100vh;
  top: 10vh;
  position: absolute;
`;

export default RightTest;
