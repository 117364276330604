import React from "react";
import styled from "styled-components";

const Branding = () => (
  <Wrapper>
    <div style={{ height: 120 }}>
      <Logo />
    </div>
    <RotatedText style={{ transform: "rotate(-90deg)" }}>
      Arxiv Search
    </RotatedText>
    <RotatedText style={{ transform: "rotate(-90deg)" }}>by Lawder</RotatedText>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 24px;
  width: 92px;
  border-right: solid 1px #ededed;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Logo = styled.div`
  width: 44px;
  height: 44px;
  background: #ededed;
  border-radius: 8px;
`;

const RotatedText = styled.div`
  width: 120px;
  padding-bottom: 75px;
`;

export default Branding;
