import React from "react";
import styled, { keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import ArrowIcon from "./Icons/ArrowIcon";
import IconLightbulb from "./Icons/IconLightbulb";
import { topics } from "./topics";

const EmptyState = (props) => {
  const [inspoMode, setInspoMode] = React.useState(false);

  return (
    <Wrapper>
      <Instruction>
        <ArrowWrapper>
          <ArrowIcon />
        </ArrowWrapper>
        Search any topic...
      </Instruction>
      <Content>
        <AnimatePresence>
          {!inspoMode && (
            <motion.div
              // variants={buttonVariants}
              // initial={false}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}

              // animate={paperMode ? "open" : "closed"}
            >
              <Headline>An interface to explore research.</Headline>
              <Subtext>
                People publish a lot of fascinating research out to the world,
                yet the tools to consume this research are quite primitive. This
                is an interface optimized for exploration: please wander about
                topics and papers to your brain's content. :)
              </Subtext>
              <InspoButton onClick={() => setInspoMode(true)}>
                <IconWrapper>
                  <IconLightbulb />
                </IconWrapper>
                Need some inspiration?
              </InspoButton>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {inspoMode && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ y: -16, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ delay: 0.5 }}
            >
              <Topics handleSearch={props.handleSearch} />
            </motion.div>
          )}
        </AnimatePresence>
      </Content>
      {!inspoMode && (
        <Credit>
          All papers sourced from{" "}
          <Link href="https://arxiv.org/" target="_blank">
            arXiv
          </Link>{" "}
          <span style={{ opacity: 0.5 }}>♥</span>
          {/* All credit goes to the authors
          of each paper. */}
        </Credit>
      )}
    </Wrapper>
  );
};

const Topics = (props) => {
  const [parentSelected, setParentSelected] = React.useState();

  const { handleSearch } = props;

  return (
    <div style={{ display: "flex", paddingBottom: 24 }}>
      <div>
        <motion.p
          style={{ paddingLeft: 8, fontSize: 15 }}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 0.5, x: 0 }}
          transition={{ delay: 0.7 }}
        >
          Select a category...
        </motion.p>
        <List>
          {Object.keys(topics).map((topic) => {
            return (
              <ParentTopic
                selected={
                  parentSelected &&
                  parentSelected.displayName === topics[topic].displayName
                }
                onClick={() => setParentSelected(topics[topic])}
              >
                {topics[topic].displayName}
              </ParentTopic>
            );
          })}
        </List>
      </div>
      {parentSelected && (
        <div>
          <motion.p
            style={{ paddingLeft: 8, fontSize: 15 }}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 0.5, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            A tiny subset of topics:
          </motion.p>
          <List
            // style={{ paddingLeft: 8, fontSize: 15 }}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            // transition={{ delay: 0.7 }}
          >
            {parentSelected.children.map((childTopic) => (
              <ChildTopic
                onClick={() =>
                  handleSearch(childTopic.value || childTopic.name)
                }
              >
                {childTopic.name}
              </ChildTopic>
            ))}
          </List>
        </div>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  padding-top: 80px;
  padding-left: 64px;

  background: radial-gradient(
        55.87% 55.87% at 35.49% -38.37%,
        rgb(255, 255, 255) 10%,
        rgba(255, 255, 255, 0) 120%
      )
      0% 0% / 100% 1480px no-repeat,
    radial-gradient(
      86.17% 106.17% at 110% 0px,
      rgb(0, 255, 0) -30%,
      rgba(255, 255, 255, 0) 80%
    ),
    radial-gradient(
      31.96% 35.68% at 0px 10%,
      rgb(255, 255, 255) -42.94%,
      rgba(255, 255, 255, 0) 100%
    );

  min-height: 100vh;
  @media screen and (max-width: 1080px) {
    padding: 24px;
    min-height: 0;
    background: none;
  }
`;

const Instruction = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  font-size: 14px;
  opacity: 0.5;
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const Content = styled.div`
  margin-top: 80px;
  // opacity: 0.4;
  @media screen and (max-width: 1080px) {
    margin-top: 24px;
  }
`;

const move1 = keyframes`
  20% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-8px);   
  }
  30% {
    transform: translateX(0px);
  }
  35% {
    transform: translateX(-8px);
  }
  40% {
    transform: translateX(0px);
  }
`;

const ArrowWrapper = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 8px;
  animation: ${move1} 5s ease-out infinite;
`;

const Headline = styled.h2`
  font-size: 24px;
  font-weight: 400;
  font-family: "Nantes", "Inter", sans-serif;
  @media screen and (max-width: 1080px) {
    font-size: 20px;
  }
`;

const Subtext = styled.p`
  line-height: 1.5;
  opacity: 0.5;
  margin-top: 8px;
  max-width: 700px;
  @media screen and (max-width: 1080px) {
    font-size: 15px;
  }
`;

const InspoButton = styled.button`
  margin-top: 16px;
  // padding: 12px 16px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  background: transparent;
  opacity: 0.5;
  transition: all 150ms ease-out;
  :hover {
    opacity: 1;
    color: #12cf31;
    // background: #ededed;
  }
  :hover svg path {
    fill: #12cf31;
  }
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 8px;

  & svg path {
    transition: all 150ms ease-out;
  }
`;

const List = styled(motion.ul)`
  list-style: none;
  margin: 0;
  margin-top: 16px;
  padding: 0;
  margin-right: 24px;
  width: 300px;
  flex-shrink: 0;
`;

const ParentTopic = styled.li`
  padding: 8px 10px;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 20ms ease-in;
  background: ${(props) =>
    props.selected ? "rgba(0, 255, 0, 0.14)" : "transparent"};
  :hover {
    background: ${(props) =>
      props.selected ? "rgba(0, 255, 0, 0.14)" : "rgba(0, 0, 0, 0.05)"};
  }
`;

const ChildTopic = styled.li`
  padding: 8px 10px;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 20ms ease-in;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Credit = styled.span`
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 12px;
  opacity: 0.5;
  @media screen and (max-width: 1080px) {
    padding-right: 24px;
    bottom: 24px;
    left: 24px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  border-bottom: solid 1px lightgrey;
  color: black;
  :hover {
    border-color: black;
  }
`;

export default EmptyState;
