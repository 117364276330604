import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const SortingTabs = (props) => {
  // const [sortBy, setSort] = React.useState("relevance");

  const containerRef = React.useRef();
  const relevanceRef = React.useRef();
  const recentRef = React.useRef();
  const [containerRect, setContainerRect] = React.useState();

  const [relevanceRect, setRelevanceRect] = React.useState();
  const [recentRect, setRecentRect] = React.useState();
  const [highlightPos, setHighlightPos] = React.useState({
    left: 0,
    right: "100%",
  });

  useEffect(() => {
    // slight hack
    if (!relevanceRect) {
      return;
    }
    if (props.sortBy === "relevance") {
      setRelevance();
    } else if (props.sortBy === "recent") {
      setRecent();
    }
  }, [props.sortBy, relevanceRect]);

  useEffect(() => {
    setContainerRect(containerRef.current.getBoundingClientRect());
  }, [containerRef]);

  // get the bounding rectangle for each tab
  useEffect(() => {
    setRelevanceRect(relevanceRef.current.getBoundingClientRect());
  }, [relevanceRef]);

  useEffect(() => {
    setRecentRect(recentRef.current.getBoundingClientRect());
  }, [recentRef]);

  const setRelevance = () => {
    setHighlightPos({
      left: relevanceRect.left - containerRect.left,
      right: containerRect.right - relevanceRect.right,
    });
  };

  const setRecent = () => {
    setHighlightPos({
      left: recentRect.left - containerRect.left,
      right: containerRect.right - recentRect.right,
    });
  };

  return (
    <SortWrapper>
      <SortText>Sort by</SortText>
      <SortButtonsWrapper ref={containerRef}>
        <TabButton
          ref={relevanceRef}
          active={props.sortBy === "relevance"}
          onClick={() => props.handleSort("relevance")}
        >
          Relevance
        </TabButton>
        <TabButton
          ref={recentRef}
          style={{ marginLeft: 8 }}
          active={props.sortBy === "recent"}
          onClick={() => props.handleSort("recent")}
        >
          Most recent
        </TabButton>
        <Highlight
          positionTransition={{
            bounceDamping: 3,
          }}
          style={{ left: highlightPos.left, right: highlightPos.right }}
        />
      </SortButtonsWrapper>
    </SortWrapper>
  );
};

const SortWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 24px;
`;

const SortButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SortText = styled.span`
  font-size: 14px;
  color: #757575;
  font-weighjt: 500;
`;

const TabButton = styled.button`
  background: none;
  outline: none;
  // border: solid 1px grey;
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 1;
  }
`;

const Highlight = styled(motion.div)`
  position: absolute;
  display: inline-block;
  background: rgba(0, 0, 0, 0.08);
  height: 24px;
  // top: 12px;
  pointer-events: none;
  transition: all 200ms ease-out;
  border-radius: 100px;
  z-index: -1;
`;

export default SortingTabs;
